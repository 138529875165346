import React from "react";
import { connect } from "react-redux";
import ActionCreator from "../ActionCreator";
import * as Ant from "antd";
const queryString = require("query-string");
import { navigate } from "gatsby";

class ValidationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    let { location, appActions } = this.props;

    try {
      const queryParams = queryString.parse(location.search);
      let access_token = queryParams.access_token; //validation_token
      navigate(`/register?access_token=${access_token}`);
    } catch (ex) {
      console.warn(ex);
    }
  }

  render() {
    return (
      <div
        style={{
          width: "100%",
          padding: 20,
        }}
      >
        <div
          style={{
            maxWidth: 600,
            minHeight: 300,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Ant.Spin />
          <div style={{ marginLeft: 20, fontSize: 25 }}>
            正在跳轉至註冊頁...
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, ActionCreator)(ValidationPage);
